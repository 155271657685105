<template>
  <div class="wrapper">
    <div class="progress-bar" v-show="showAnimation">
      <div class="progress-line"></div>
    </div>

    <div class="sign-in-up forgot-password" id="final-screen">
      <div class="card-wrapper">
        <div class="card-container">
          <div v-show="!showAnimation">
            <div class="card-shadow"></div>
            <div class="card-shadow"></div>
            <div class="card">
              <div class="card-item">
                <div class="card-img text-center">
                  <div class="position-relative">
                    <submit-app-svg />
                    <div
                      v-if="getAssets.roundColorTeamLogo"
                      class="client-logo position-absolute"
                    >
                      <img
                        class="submit-app-team-logo"
                        :src="getAssets.roundColorTeamLogo"
                        alt="Team-Logo"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-title">
                  <h2>Submit Your Application!</h2>
                </div>
                <p>
                  {{ getTheNames() }}, you're ready to submit your application!
                  By clicking Submit, you authorize us to obtain a copy of your
                  credit report.
                </p>
                <button
                  type="button"
                  class="mb-4 btn btn-red"
                  @click="createMismoFile"
                >
                  <span>Submit</span>
                </button>
                <button
                  type="button"
                  class="mb-4 btn btn-outline"
                  @click="goBack"
                >
                  <span>Back</span>
                </button>
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#infoModal"
                  class="text-underline"
                  >{{ title }}</a
                >
              </div>
            </div>
          </div>

          <div class="card" v-show="showAnimation">
            <setting-up-portal :cp-url="cpUrl" />
          </div>
        </div>
      </div>

      <info-modal :title="title" :description="description" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubmitAppSvg from "./SubmitAppSvg.vue";
import InfoModal from "./InfoModal.vue";
import userDeviceBrowser from "./../mixins/userDeviceBrowser";
import SettingUpPortal from "./SettingUpPortal.vue";

export default {
  name: "Submit-Application",
  mixins: [userDeviceBrowser],
  components: { InfoModal, SubmitAppSvg, SettingUpPortal },
  data() {
    return {
      signInBg: "",
      mismoFileCreated: false,
      title: "",
      description: "",
      clientIpAddress: "",
      clientDevice: "",
      clientBrowser: "",
      showAnimation: false,
      cpUrl: "",
    };
  },
  watch: {
    signInBg(newVal) {
      if (newVal) {
        let theBox = document.getElementById("final-screen");

        theBox.style.backgroundImage = `url(${newVal})`;
        theBox.style.backgroundSize = "cover";
      }
    },
  },
  beforeMount() {
    this.fetchUserDeviceBrowser();
  },
  mounted() {
    this.signInBg = this.getAssets.agentPortalBackground;
    this.title = "Why do we need your credit report?";
    this.description =
      "We obtain a copy of your credit report to see your current credit liabilities and understand your history of managing your credit. When you submit your application, we'll obtain a copy of your credit report from one or more of the three major credit bureaus (Equifax, TransUnion and Experian), and include the results in your application. This will count as a hard inquiry on your credit, and is required when applying for a loan. If you're interested in learning more about credit reports and the different types of credit inquiries, visit the Federal Trade Commission's Guide to Credit Scores. Still have questions? <a>Contact your loan team</a>";

    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getAssets",
      "getUserId",
      "getIsMismoFileCreated",
      "getQuestions",
      "getActiveQuestion",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchIsMismoFileCreated",
      "fetchLoaderStatus",
      "fetchActiveQuestion",
      "fetchEditModeFlag",
    ]),

    getTheNames() {
      let applicant = this.getQuestions.find(
        (v) =>
          v.questionFieldMapping === "firstname__c" ||
          v.questionFieldMapping === "candidoem__firstname__c"
      );

      let co_applicant = this.getQuestions.find(
        (v) =>
          v.questionFieldMapping === "first_name_co_borrower__c" ||
          v.questionFieldMapping === "candidoem__first_name_co_borrower__c"
      );

      let applicant_name;
      let co_applicant_name;

      if (applicant) applicant_name = applicant.questionAnswer;
      if (co_applicant) co_applicant_name = co_applicant.questionAnswer;

      let isCoApplicantAdded = this.getQuestions.some(
        (x) =>
          (x.questionFieldMapping === "is_joint_applicant_added__c" ||
            x.questionFieldMapping ===
              "candidoem__is_joint_applicant_added__c") &&
          x.questionAnswer === "Yes"
      );

      return isCoApplicantAdded && applicant_name && co_applicant_name
        ? applicant_name + " & " + co_applicant_name
        : applicant_name;
    },

    async getClientIpAddress() {
      await this.$http.get("https://api.ipify.org").then(({ data }) => {
        this.clientIpAddress = data;
      });
    },

    async createMismoFile() {
      this.showAnimation = true;
      this.progressBar();

      await this.getClientIpAddress();

      let payLoad = {
        prospectId: this.getUserId,
        orgId: this.getOrgId,
        ipAddress:
          this.clientIpAddress +
          "&&" +
          this.clientBrowser +
          "&&" +
          this.clientDevice,
      };

      await this.$http
        .post("/account/mismoFileClone", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success(resp.message);
            this.fetchIsMismoFileCreated(true);
            this.cpUrl = resp.encryptedURL;
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      // this.fetchLoaderStatus(false);
    },

    progressBar() {
      let elem = document.querySelector(".progress-line");
      let width = 1;
      let time = 18000;

      let progress = setInterval(() => {
        if (width >= 100) {
          clearInterval(progress);
        } else {
          width++;
          elem.style.width = width + "%";
        }
      }, time / 100);
    },

    goBack() {
      this.fetchEditModeFlag(true);
      this.$router.push("/questionnaire");
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative !important;
}

.client-logo.position-absolute {
  position: absolute !important;
  right: -4%;
  bottom: -3%;
}

.submit-app-team-logo {
  max-width: 95px;
}

.progress-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 10px;
  background: #ebebeb;
}

.progress-line {
  position: absolute;
  width: 0%;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 0 8px 8px 0;
  z-index: 9;
  transition: width 0.3s linear;
  -webkit-transition: width 0.3s linear;
  -moz-transition: width 0.3s linear;
  -ms-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
}
</style>